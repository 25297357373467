import './Rules.css';

const Rules = () => (
  <section className="rules" id="rules">
    <div className="container rules-container">
      <div className="rules-title">
        <h3>Правила игры</h3>
      </div>
      <div className="rules-text">
        <p>Вы играете с роботом.</p>
        <ol>
          <li>
            Игра начинается с того, что пользователь предлагает любое существительное в единственном
            числе, которое начинается с любой буквы.
          </li>
          <li>
            Робот отвечает существительным в единственном числе, которое начинается
            на последнюю букву предложенного пользователем слова.
          </li>
          <li>
            После первого ответа робота включается таймер, и пользователю на ответ даётся 30 секунд.
            За каждое новое слово пользователю добавляется 10 секунд.
            Максимальное время, которое можно набрать - 10 минут.
          </li>
          <li>
            Пользователь продолжает игру, предлагая слово, которое начинается
            на последнюю букву слова робота, и так далее.
          </li>
          <li>
            Если слово, предложенное игроком, оканчивается на буквы, из которых невозможно составить
            новое слово (например, «ь» или «ы»), то игрок должен начать
            новое слово, используя предпоследнюю букву предыдущего слова.
          </li>
          <li>
            Слова, предлагаемые игроками, должны соответствовать
            русскому литературному языку.
          </li>
          <li>Буквы «е» и «ё» - взаимозаменяемы («ёлка\елка», «ковер\ковёр» и т. д.)</li>
          <li>Слова не должны повторяться. Допускаются однокоренные слова.</li>
          <li>
            Игра продолжается до тех пор, пока игроки могут предлагать новые существительные
            в единственном числе, соответствующие правилам игры, или пока у пользователя
            не кончится время на ответ.
          </li>
          <li>Побеждает игрок, соперник которого первым не сможет придумать новое слово.</li>
          <li>
            Нажатием на кнопку «Завершить игру» пользователь
            обнуляет результаты текущей игры, и может начать игру с нуля.
          </li>
        </ol>
      </div>
    </div>
  </section>
);

export default Rules;
